
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    :close-on-click-modal="false"
    style="text-align: left"
    width="80%"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'150px'"
        inline
      >
        <el-form-item label="楼栋" prop="building">
          <el-input
            v-model="formModel.building"
            placeholder="请输入楼栋"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="房号" prop="room">
          <el-input
            v-model="formModel.room"
            placeholder="请输入房号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="企业名称" prop="name">
          <el-input
            v-model="formModel.name"
            placeholder="请输入企业名称"
            style="width: 300px"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="企业名称" prop="companyId">
          <el-select-tree
            :props="companyProps"
            :options="companyResult"
            v-model="formModel.companyId"
            style="width: 300px"
            size="mediumn"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="联系人" prop="artificialPerson">
          <el-select
            filterable
            v-model="formModel.artificialPerson"
            style="width: 300px"
          >
            <el-option v-for="person in personResult" 
              :key="person.id" :value="person.id" :label="person.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="法人" prop="boss">
          <el-input
            v-model="formModel.boss"
            placeholder="请输入法人"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="面积（㎡）" prop="area">
          <el-input
            v-model="formModel.area"
            placeholder="请输入面积"
            style="width: 300px"
            oninput ="value=value.replace(/[^0-9.]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="入驻时间" prop="checkinTime">
          <el-date-picker v-model="formModel.checkinTime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" style="width: 300px"></el-date-picker>
        </el-form-item>
        <el-form-item label="费用到期时间" prop="feeExpirationTime">
          <el-date-picker v-model="formModel.feeExpirationTime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" style="width: 300px"></el-date-picker>
        </el-form-item>
        <el-form-item label="合同到期时间" prop="contractExpirationTime">
          <el-date-picker v-model="formModel.contractExpirationTime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" style="width: 300px"></el-date-picker>
        </el-form-item>
        <el-form-item label="租赁期限" prop="leaseTerm">
          <el-input
            v-model="formModel.leaseTerm"
            placeholder="请输入租赁期限"
            style="width: 300px"
          ></el-input>
      </el-form-item>
        <el-form-item label="是否支持荆鹏电管家" prop="enableDgj">
          <el-switch
            v-model="formModel.enableDgj"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <!-- <el-form-item label="租金" prop="rent">
          <el-input
            v-model="formModel.rent"
            placeholder="请输入租金"
            style="width: 300px"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="缴纳方式" prop="paymentMethod">
          <el-select v-model="formModel.paymentMethod" style="width:300px;" clearable>
          <el-option v-for="(item,index) in paymentMethodList" 
          :label="item.name" :value="item.value" :key="index"></el-option>
        </el-select>
        </el-form-item> -->
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import checkinInfoApi from "@/api/base/checkinInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import companyInfoApi from "@/api/base/companyInfo";
import personInfoApi from "@/api/base/personInfo";
import SelectTree from "@/components/SelectTree";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        id: [{ required: true, message: "编号不能为空", trigger: "blur" }],
        building: [
          { required: true, message: "楼栋不能为空", trigger: "blur" },
        ],
        companyId: [
          { required: true, message: "企业不能为空", trigger: "blur" },
        ],
        artificialPerson: [
          { required: true, message: "联系人不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      paymentMethodList: [],
      companyResult: [],
      companyProps: {
        value: "id",
        label: "name",
        children: "children"
      },
      personResult: [],
      personProps: {
        value: "id",
        label: "name",
        children: "children"
      },
    };
  },
  created() {
    var self = this;

    dataDictionaryApi.findByCatalogName({
      "catalogName" : "缴纳方式"
    }).then(response => {
      var jsonData = response.data;
      this.paymentMethodList = jsonData.data;
    });

    companyInfoApi.list({scope:"all"}).then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });
  },
  watch:{
    "formModel.companyId": function (val, oldval) {
      if (val != null && val != "") {
        var self = this;
        var formData = new FormData();
        formData.append("companyId", val);

        personInfoApi.findByCompanyId(formData).then(function(response) {
          var jsonData = response.data;
          if (jsonData.result) {
            self.personResult = jsonData.data;
          }
        });
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return checkinInfoApi.add(self.formModel);
            } else {
              return checkinInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return checkinInfoApi.create();
      } else {
        return checkinInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          
          //var personId = self.formModel.artificialPerson;
          //实际保存为personId，但数据库中是varchar类型
          //self.formModel.artificialPerson = parseInt(personId);
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree
  },
};
</script>