import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/checkinInfo/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/checkinInfo/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/checkinInfo/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/checkinInfo/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/checkinInfo/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/checkinInfo/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/checkinInfo/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function updateWechatPayOrAliPay(formModel) {
  return request.post(constant.serverUrl + "/base/checkinInfo/updateWechatPayOrAliPay", formModel);
}

export default {
  create,edit,add,update,remove,batchRemove,pageList,updateWechatPayOrAliPay
}