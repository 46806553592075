<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">园区管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/base/checkinInfo">租户管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="楼栋" prop="building">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.building"
        ></el-input>
      </el-form-item>
      <el-form-item label="企业名称" prop="name">
        <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
      </el-form-item>
      <el-form-item label="费用到期" prop="feeExpirationTime">
        <el-date-picker v-model="queryModel.feeExpirationTime" value-format="yyyy-MM-dd" type="date" size="mini" placeholder="选择日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="合同到期" prop="contractExpirationTime">
        <el-date-picker v-model="queryModel.contractExpirationTime" value-format="yyyy-MM-dd" type="date" size="mini" placeholder="选择日期"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-upload2"
        @click="batchImportVisible = true"
        >导入</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="companyName"
        label="企业名称"
        width="180"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="building"
        label="楼栋"
        width="180"
      ></el-table-column>
      <el-table-column prop="room" label="房号" width="180"></el-table-column>
      <el-table-column
        prop="boss"
        label="法人"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="artificialPersonN"
        label="联系人"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="artificialPersonPhone"
        label="联系电话"
        width="180"
      ></el-table-column>
      <el-table-column prop="area" label="面积（㎡）" width="180"></el-table-column>
      <el-table-column
        prop="checkinTime"
        label="入驻时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="feeExpirationTime"
        label="费用到期时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="contractExpirationTime"
        label="合同到期时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="leaseTerm"
        label="租赁期限"
        width="180"
      ></el-table-column>
      <!-- <el-table-column prop="wechatPay" fixed="right" label="微信支付" width="80">
          <template slot-scope="{row}">
              <el-switch
                  v-model="row.wechatPay"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="handlChange(row)">
              </el-switch>
          </template>
      </el-table-column>
      <el-table-column prop="aliPay" fixed="right" label="支付宝支付" width="90">
          <template slot-scope="{row}">
              <el-switch
                  v-model="row.aliPay"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="handlChange(row)">
              </el-switch>
          </template>
      </el-table-column> -->
      <el-table-column label="操作" fixed="right" width="220">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="handlePayment(row)"
            >收费项</el-button
          >
          <el-button size="mini" type="warning" @click="handleEdit(row)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" @click="handleDelete(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <checkinInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></checkinInfo-detail>
    <checkinInfo-payment
      v-if="showModal1"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></checkinInfo-payment>
    <el-dialog
      title="批量导入租户"
      :visible.sync="batchImportVisible"
      :modal-append-to-body="false"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form label-width="150px">
        <el-form-item label="模板下载">
          <el-link
            href="http://rccs.oss-cn-hangzhou.aliyuncs.com/smart_school/personInfo/2020/10/%E5%B0%8F%E9%B9%8F%E7%AE%A1%E5%AE%B6%E7%A7%9F%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls"
            type="primary"
            target="_blank"
            >点击下载模板</el-link
          >
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            accept=".xls"
            :action="uploadUrlXls"
            :headers="headers"
            :on-preview="handleBatchImportPreview"
            name="uploadFile"
            :multiple="true"
            :limit="1"
            :on-remove="handleBatchImportRemove"
            :before-remove="beforeBatchImportRemove"
            :before-upload="beforeUpload"
            :on-exceed="handleBatchImportExceed"
            :on-success="handleBatchImportSuccess"
            :file-list="batchImportFileList"
          >
            <el-button size="small" type="primary" :loading="xlsLoading"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">
              只能上传xls文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="batchImportVisible = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";
import CheckinInfoDetail from "./checkinInfo-detail";
import CheckinInfoPayment from "./checkinInfo-payment";
import checkinInfoApi from "@/api/base/checkinInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/auth";

export default {
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        building: "",
        name: "",
        feeExpirationTime: "",
        contractExpirationTime: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      showModal1: false,
      modalTitle: "",
      businessKey: "",
      batchImportVisible: false,
      uploadUrlXls: Constant.serverUrl + "/base/checkinInfo/importXls",
      batchImportFileList: [],
      xlsLoading: false,
      headers: {
        Authorization: getToken(),
      },
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("building", self.queryModel.building);
      formData.append("name", self.queryModel.name);
      formData.append("feeExpirationTime", self.queryModel.feeExpirationTime);
      formData.append("contractExpirationTime", self.queryModel.contractExpirationTime);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      checkinInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handlePayment(record) {
      this.modalTitle = "收费项";
      this.businessKey = record.id;
      this.showModal1 = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          checkinInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        checkinInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      this.showModal1 = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handlChange(record){
      var self = this;
      var formData = new FormData();
      self.loading = true;

      formData.append("id", record.id);
      formData.append("wechatPay", !!record.wechatPay);
      formData.append("aliPay", !!record.aliPay);

      checkinInfoApi.updateWechatPayOrAliPay(formData).then(function (response) {
          var jsonData = response.data;
          self.loading = false;
          if (jsonData.result) {
              self.changePage(self.pageIndex);
          }
          else{
              self.$message.error(jsonData.message + "");
          }
      });
    },
    beforeUpload(file, fileList) {
      //导入前判断
      var self = this;
      self.xlsLoading = true;
      return true;
    },
    //批量导入-上传成功
    handleBatchImportSuccess(response, file, fileList) {
      var self = this;
      self.xlsLoading = false;
      
      if (response.result) {
        self.$message.success(response.message);
        this.batchImportFileList = [];
        this.changePage(1);

        this.batchImportVisible = false;
      } else {
        //this.$message.error(response.message);
        this.batchImportFileList = [];
        this.changePage(1);

        if (response.data != null) {
          //下载有错误信息提示的报表
          //window.open(response.data);
          self.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message:
              response.message +
              `,<a href="${response.data}" target="_blank">点击下载未导入的数据报表</a>&nbsp;`,
            duration: 30000,
          });
        }
      }
    },
    //批量导入-预览
    handleBatchImportPreview(file) {
      console.log(file.url);
    },
    //批量导入-移除
    handleBatchImportRemove(file, fileList) {
      console.log(file, fileList);
    },
    //批量导入-移除前操作
    beforeBatchImportRemove(file, fileList) {
      console.log(file, fileList);
    },
    //批量导入-文件超出个数限制时的钩子
    handleBatchImportExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "checkinInfo-detail": CheckinInfoDetail,
    "checkinInfo-payment": CheckinInfoPayment,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>