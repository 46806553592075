<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    :close-on-click-modal="false"
    style="text-align: left"
    width="80%"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="fromModel">
        <el-table
              ref="formTable"
              :data="tableData"
              v-loading="loading"
              element-loading-text="拼命加载中"
              stripe
          >
              <el-table-column prop="name" label="收费项目" ></el-table-column>
              <el-table-column prop="desc" label="收费项目说明" ></el-table-column>
              <el-table-column label="单价">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.unitPrice" oninput ="value=value.replace(/[^0-9.]/g,'')" />
                </template>
              </el-table-column>
              <el-table-column prop="unit" label="单位" ></el-table-column>
          </el-table>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import checkinInfoApi from "@/api/base/checkinInfo";
import payServiceApi from "@/api/base/payService";
import SelectTree from "@/components/SelectTree";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      fromModel:{},
      tableData: [],
      showDialog: true,
      loading: false,
      submitting: false,
    };
  },
  created() {
    var self = this;
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    changePage(pageIndex) {
      var self = this;
        this.loading = true;
        var formData = new FormData();
        formData.append("businessKey", self.businessKey);

        payServiceApi.payServiceList(formData).then(response => {
            this.loading = false;
            var jsonData = response.data;

            console.log(jsonData);

            this.tableData = jsonData.data.data;
            this.totalElements = jsonData.data.recordsTotal;
        });
    },
    handleSubmit() {
      var self = this;
      
      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            return payServiceApi.addList(self.tableData);
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    // "el-select-tree": SelectTree
  },
};
</script>