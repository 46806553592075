import request from '@/utils/request'
import constant from '@/constant'

function payServicePageList(formData) {
  return request.post(constant.serverUrl + "/base/goodsInfo/payServicePageList", formData);
}

function goodsTypeList() {
  return request.post(constant.serverUrl + "/base/goodsInfo/goodsTypeList");
}

function addPayService(formModel){
  return request.post(constant.serverUrl + "/base/goodsInfo/addPayService", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function editPayService(id){
  return request.get(constant.serverUrl + "/base/goodsInfo/editPayService/" + id);
}


function update(formModel){  
  return request.post(constant.serverUrl + "/base/goodsInfo/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function create(){
  return request.get(constant.serverUrl + "/base/goodsInfo/create");
}

function remove(id){
  return request.post(constant.serverUrl + "/base/goodsInfo/delete/" + id);
}

function payServiceList(formData) {
  return request.post(constant.serverUrl + "/base/goodsInfo/payServiceList", formData, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function addList(formData) {
  return request.post(constant.serverUrl + "/base/goodsInfo/addList", formData, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
    payServicePageList,goodsTypeList,addPayService,update,editPayService,create,remove,payServiceList,addList
  }